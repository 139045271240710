<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>审核</a-breadcrumb-item>
              <a-breadcrumb-item>审核中心</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div class="Enter-top-right">
          <a-button
            type="primary"
            :disabled="selectedRowKey.length > 0 ? false : true"
            @click.prevent="showaudit"
          >
            批量审核
          </a-button>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-modal
          title="批量审批"
          :visible="visible"
          maskClosable="true"
          :confirm-loading="confirmLoading"
          @ok="handleOkaudit(2)"
          @cancel="handleOkaudit(3)"
        >
          <a-textarea
            placeholder="请输入审批意见"
            v-model="form.auditNote"
            :rows="4"
          />
        </a-modal>
        <a-form
          style="padding: 0 24px; margin-top: 3px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flow-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-if="i == '审批类型'"
                  v-model="params['link_type'].value"
                  placeholder="请选择审批类型"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 铺位调整 </a-select-option>
                  <a-select-option value="1"> 意向合同提交 </a-select-option>
                  <a-select-option value="3"> 核销 </a-select-option>
                  <a-select-option value="21"> 正式合同提交 </a-select-option>
                  <a-select-option value="22"> 正式合同重写 </a-select-option>
                  <a-select-option value="23"> 正式合同作废 </a-select-option>
                  <a-select-option value="24">
                    正式合同变更主体
                  </a-select-option>
                  <a-select-option value="25">
                    正式合同变更条款
                  </a-select-option>
                </a-select>
                <div v-else-if="i == '提交时间'">
                  <a-date-picker
                    placeholder="请输入开始日期"
                    v-model="params['submit_time'].value"
                    style="width: 173.5px"
                  /><span class="a-col-span">-</span>
                  <a-date-picker
                    v-model="params['submit_time#2'].value"
                    style="width: 173.5px"
                    placeholder="请输入结束日期"
                  />
                </div>
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div>
            <a-tabs v-model="audittype" type="card" @change="callback">
              <a-tab-pane key="0" tab="我发起的"> </a-tab-pane>
              <a-tab-pane key="1" tab="待我审批"> </a-tab-pane>
              <a-tab-pane key="2" tab="我已审批"> </a-tab-pane>
            </a-tabs>
            <a-table
              v-if="audittype == 0"
              :row-selection="rowSelections"
              rowKey="id"
              :pagination="false"
              :columns="column"
              :data-source="auditsubmitlist"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="operate" slot-scope="text">
                <a-badge
                  :status="text | statusTypeFilter"
                  :text="text | statusFilter"
                />
              </span>
              <span slot="linkType" slot-scope="text">
                {{
                  text == 0
                    ? '铺位调整'
                    : text == 1
                    ? '意向合同提交'
                    : text == 3
                    ? '核销'
                    : text == 21
                    ? '正式合同提交'
                    : text == 22
                    ? '正式合同重写'
                    : text == 23
                    ? '正式合同作废'
                    : text == 24
                    ? '正式合同变更主体'
                    : '正式合同变更条款'
                }}
              </span>
            </a-table>
            <a-table
              v-else-if="audittype == 1"
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="auditcenterlist"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="linkType" slot-scope="text">
                {{
                  text == 0
                    ? '铺位调整'
                    : text == 1
                    ? '意向合同提交'
                    : text == 3
                    ? '核销'
                    : text == 21
                    ? '正式合同提交'
                    : text == 22
                    ? '正式合同重写'
                    : text == 23
                    ? '正式合同作废'
                    : text == 24
                    ? '正式合同变更主体'
                    : text == 25
                    ? '正式合同变更条款'
                    : '正式合同终止'
                }}
              </span>
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">审核</a>
                <!-- <a @click="ondetail(record)">详情</a> -->
              </template>
            </a-table>
            <a-table
              v-else-if="audittype == 2"
              :row-selection="rowSelections"
              rowKey="id"
              :pagination="false"
              :columns="column"
              :data-source="auditHistorylist"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="operate" slot-scope="text">
                <a-badge
                  :status="text | statusTypeFilter"
                  :text="text | statusFilter"
                />
              </span>
              <span slot="linkType" slot-scope="text">
                {{
                  text == 0
                    ? '铺位调整'
                    : text == 1
                    ? '意向合同提交'
                    : text == 3
                    ? '核销'
                    : text == 21
                    ? '正式合同提交'
                    : text == 22
                    ? '正式合同重写'
                    : text == 23
                    ? '正式合同作废'
                    : text == 24
                    ? '正式合同变更主体'
                    : '正式合同变更条款'
                }}
              </span>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
            <a-modal
              @cancel="onauditClose"
              width="930px"
              title="审核信息"
              :visible="auditvisible"
            >
              <a-descriptions
                v-if="detaillist.linkType == 0"
                :column="2"
                title="基础信息 (铺位调整)"
                bordered
              >
                <a-descriptions-item label="类型">
                  {{ linklist.type == 0 ? '拆分' : '合并' }}
                </a-descriptions-item>
                <a-descriptions-item label="简介">
                  {{ detaillist.description }}
                </a-descriptions-item>
                <a-descriptions-item label="原铺位名">
                  {{ linklist.oldNames }}
                </a-descriptions-item>
                <div v-show="linklist.type == 1">
                  <a-descriptions-item label="新铺位名">
                    {{ linklist.newNames }}
                  </a-descriptions-item>
                  <a-descriptions-item label="新铺位计租面积">
                    {{ linklist.news.rentArea }}
                  </a-descriptions-item>
                  <a-descriptions-item label="新铺位使用面积">
                    {{ linklist.news.useArea }}
                  </a-descriptions-item>
                  <a-descriptions-item label="新铺位建筑面积">
                    {{ linklist.news.totalArea }}
                  </a-descriptions-item>
                </div>
                <a-descriptions-item v-if="linklist.type == 0" label="新铺位">
                  <div
                    class="flow-d-row"
                    v-for="item in linklist.news"
                    :key="item.id"
                  >
                    <a-form-model-item class="ant--input" label="铺位名称">
                      {{ item.name }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="计租面积">
                      {{ item.rentArea }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="使用面积">
                      {{ item.useArea }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="建筑面积">
                      {{ item.totalArea }}
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="提交备注" :span="4">
                  {{ submitNote }}
                </a-descriptions-item>
                <a-descriptions-item label="提交附件">
                  <a-upload
                    action=""
                    :file-list="subfile"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
              </a-descriptions>
              <a-descriptions
                v-if="detaillist.linkType == 1"
                :column="2"
                title="基础信息 (意向合同提交)"
                bordered
              >
                <a-descriptions-item label="简介">
                  {{ detaillist.description }}
                </a-descriptions-item>
                <a-descriptions-item label="意向名称" :span="2">
                  {{ linklist.name }}
                </a-descriptions-item>
                <a-descriptions-item label="合同主体" :span="2">
                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    class="flow-d-row"
                  >
                    <a-form-model-item style="width: 32%" label="出租方名称">
                      <a-tooltip>
                        <template slot="title">
                          {{ linklist.partyAName }}
                        </template>
                        {{ linklist.partyAName | text }}
                      </a-tooltip>
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="出租方联系电话"
                    >
                      {{ linklist.partyAContact }}
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="出租方联系地址"
                    >
                      <a-tooltip>
                        <template slot="title">
                          {{ linklist.partyAAddress }}
                        </template>
                        {{ linklist.partyAAddress | text }}
                      </a-tooltip>
                    </a-form-model-item>
                    <a-form-model-item style="width: 32%" label="承租方名称">
                      {{ linklist.partyBName }}
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="承租方联系电话"
                    >
                      {{ linklist.partyBContact }}
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="承租方联系地址"
                    >
                      <a-tooltip>
                        <template slot="title">
                          {{ linklist.partyBAddress }}
                        </template>
                        {{ linklist.partyBAddress | text }}
                      </a-tooltip>
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="意向合同">
                  <a-upload
                    action=""
                    :file-list="linklist.agreement"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
                <a-descriptions-item label="意向合同附件">
                  <a-upload
                    action=""
                    :file-list="linklist.files"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>

                <a-descriptions-item label="铺位">
                  <div
                    class="flow-d-row"
                    v-for="item in linklist.stores"
                    :key="item.id"
                  >
                    <a-form-model-item class="ant--input" label="楼栋">
                      {{ item.buildingName }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="楼层">
                      {{ item.floorName }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="铺位名称">
                      {{ item.storeName }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="计租面积">
                      {{ item.rentArea }}
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="提交备注" :span="4">
                  {{ submitNote }}
                </a-descriptions-item>
                <a-descriptions-item label="提交附件">
                  <a-upload
                    action=""
                    :file-list="subfile"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
              </a-descriptions>
              <a-descriptions
                v-if="detaillist.linkType == 3"
                :column="4"
                title="基础信息 (核销)"
                bordered
              >
                <a-descriptions-item label="简介" :span="2">
                  {{ detaillist.description }}
                </a-descriptions-item>
                <a-descriptions-item label="账单日期">
                  {{
                    linklist.bill.billDate == undefined
                      ? ''
                      : linklist.bill.billDate
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="支付日期">
                  {{
                    linklist.bill.payDate == undefined
                      ? ''
                      : linklist.bill.payDate
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="已支付金额">
                  {{
                    linklist.bill.payed == null ? '0' : linklist.bill.payed
                  }}元
                </a-descriptions-item>
                <a-descriptions-item label="账单金额">
                  {{
                    linklist.bill.totalFee == undefined
                      ? ''
                      : linklist.bill.totalFee
                  }}
                </a-descriptions-item>

                <a-descriptions-item label="付款人">
                  {{ linklist.payByName }}
                </a-descriptions-item>
                <a-descriptions-item label="付款金额">
                  {{ linklist.fee }}元
                </a-descriptions-item>
                <a-descriptions-item label="付款时间">
                  {{ linklist.payTime | time }}
                </a-descriptions-item>
                <a-descriptions-item label="交易单号">
                  {{ linklist.code }}
                </a-descriptions-item>
                <a-descriptions-item label="提交时间">
                  {{ linklist.createTime | time }}
                </a-descriptions-item>
                <a-descriptions-item label="支付方式">
                  {{
                    linklist.payType == '0'
                      ? '支付宝'
                      : linklist.payType == '1'
                      ? '微信'
                      : linklist.payType == '2'
                      ? 'POS机'
                      : linklist.payType == '3'
                      ? '银行转账'
                      : linklist.payType == '4'
                      ? '现金'
                      : '其他'
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="合同信息" :span="4">
                  <div
                    v-if="linklist.intention == null"
                    style="flex-wrap: wrap; justify-content: space-between"
                    class="flow-d-row"
                  >
                    <a-form-model-item style="width: 30%" label="合同编号">
                      {{ linklist.sign == null ? '' : linklist.sign.code }}
                    </a-form-model-item>
                    <a-form-model-item style="width: 30%" label="出租方">
                      <a-tooltip>
                        <template slot="title">
                          {{
                            linklist.sign == null
                              ? ''
                              : linklist.sign.partyAName
                          }}
                        </template>
                        {{
                          linklist.sign == null
                            ? ''
                            : linklist.sign.partyAName | text
                        }}
                      </a-tooltip>
                    </a-form-model-item>
                    <a-form-model-item style="width: 30%" label="承租方">
                      <a-tooltip>
                        <template slot="title">
                          {{
                            linklist.sign == null
                              ? ''
                              : linklist.sign.partyBName
                          }}
                        </template>
                        {{
                          linklist.sign == null
                            ? ''
                            : linklist.sign.partyBName | text
                        }}
                      </a-tooltip>
                    </a-form-model-item>
                  </div>

                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    class="flow-d-row"
                    v-else
                  >
                    <a-form-model-item style="width: 30%" label="合同编号">
                      {{
                        linklist.intention == null
                          ? ''
                          : linklist.intention.code
                      }}
                    </a-form-model-item>
                    <a-form-model-item style="width: 30%" label="出租方">
                      <a-tooltip>
                        <template slot="title">
                          {{
                            linklist.intention == null
                              ? ''
                              : linklist.intention.partyAName
                          }}
                        </template>
                        {{
                          linklist.intention == null
                            ? ''
                            : linklist.intention.partyAName | text
                        }}
                      </a-tooltip>
                    </a-form-model-item>
                    <a-form-model-item style="width: 30%" label="承租方">
                      <a-tooltip>
                        <template slot="title">
                          {{
                            linklist.intention == null
                              ? ''
                              : linklist.intention.partyBName
                          }}
                        </template>
                        {{
                          linklist.intention == null
                            ? ''
                            : linklist.intention.partyBName | text
                        }}
                      </a-tooltip>
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="提交备注" :span="4">
                  {{ submitNote }}
                </a-descriptions-item>
                <a-descriptions-item label="提交附件" :span="4">
                  <a-upload
                    action=""
                    :file-list="subfile"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
              </a-descriptions>
              <a-descriptions
                class="form-item"
                v-if="
                  detaillist.linkType == 21 ||
                  detaillist.linkType == 22 ||
                  detaillist.linkType == 23 ||
                  detaillist.linkType == 26
                "
                :column="2"
                :title="
                  this.detaillist.linkType == 21
                    ? '基础信息 (正式合同提交)'
                    : this.detaillist.linkType == 22
                    ? '基础信息 (正式合同重写)'
                    : this.detaillist.linkType == 23
                    ? '基础信息 (正式合同作废)'
                    : this.detaillist.linkType == 26
                    ? '基础信息 (正式合同终止)'
                    : ''
                "
                bordered
              >
                <a-descriptions-item label="简介">
                  {{ detaillist.description }}
                </a-descriptions-item>
                <a-descriptions-item label="意向名称">
                  {{ linklist.name }}
                </a-descriptions-item>

                <a-descriptions-item
                  v-if="this.detaillist.linkType == 26"
                  label="账单清算预览"
                  :span="2"
                >
                  <div class="flow-d-row">
                    <a-form-model-item class="ant--input" label="账单总金额">
                      {{ linkClear.allfee }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="物业费">
                      {{ linkClear.feeProperty }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="保证金">
                      {{ linkClear.surety }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="优惠折扣">
                      {{ linkClear.discount }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="已付金额">
                      {{ linkClear.payedRent }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="待结算金额">
                      {{ linkClear.clear }}
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="合同主体" :span="2">
                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    class="flow-d-row"
                  >
                    <a-form-model-item style="width: 32%" label="出租方名称">
                      <a-tooltip>
                        <template slot="title">
                          {{ linklist.partyAName }}
                        </template>
                        {{ linklist.partyAName | text }}
                      </a-tooltip>
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="出租方联系电话"
                    >
                      {{ linklist.partyAContact }}
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="出租方联系地址"
                    >
                      <a-tooltip>
                        <template slot="title">
                          {{ linklist.partyAAddress }}
                        </template>
                        {{ linklist.partyAAddress | text }}
                      </a-tooltip>
                    </a-form-model-item>
                    <a-form-model-item style="width: 32%" label="承租方名称">
                      {{ linklist.partyBName }}
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="承租方联系电话"
                    >
                      {{ linklist.partyBContact }}
                    </a-form-model-item>
                    <a-form-model-item
                      style="width: 30%"
                      label="承租方联系地址"
                    >
                      <a-tooltip>
                        <template slot="title">
                          {{ linklist.partyBAddress }}
                        </template>
                        {{ linklist.partyBAddress | text }}
                      </a-tooltip>
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="合同租赁条款" :span="2">
                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    class="flow-d-row"
                  >
                    <a-form-model-item style="width: 32%" label="付款周期">
                      {{ linkclause.clauseTerm.term }}
                    </a-form-model-item>
                    <a-form-model-item style="width: 30%" label="账单日期">
                      {{
                        linkclause.clauseTerm.billType == 0
                          ? '提前'
                          : linkclause.clauseTerm.billType == 1
                          ? '推后'
                          : '日期'
                      }}
                      {{ linkclause.clauseTerm.billDateNum
                      }}{{
                        linkclause.clauseTerm.billDateType == 0
                          ? '自然日'
                          : linkclause.clauseTerm.billDateType == 1
                          ? '工作日'
                          : ''
                      }}
                    </a-form-model-item>
                    <a-form-model-item style="width: 30%" label="缴费日期">
                      账单日期后{{ linkclause.clauseTerm.payDateNum
                      }}{{
                        linkclause.clauseTerm.payDateType == 0
                          ? '自然日'
                          : '工作日'
                      }}
                    </a-form-model-item>
                    <!-- <a-form-model-item style="width:30%" label="首期缴费日期">
                      {{
                        linklist.clause.clauseTerm.billDate == null
                          ? ""
                          : linklist.clause.clauseTerm.billDate
                      }}
                    </a-form-model-item> -->
                  </div>
                  <!-- //租赁 -->
                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    v-for="item in linkclause.clauseTerm.termDurations"
                    :key="item.id"
                    class="flow-d-row"
                  >
                    <a-form-model-item label="铺位">
                      <a-select
                        mode="multiple"
                        disabled="disabled"
                        placeholder="暂无"
                        style="width: 100%; position: relative; margin-top: 3px"
                        @change="onChangestore(index)"
                        v-model="item.store"
                      >
                        <a-select-option
                          v-for="item in linklist.stores"
                          :key="item.storeId"
                        >
                          {{ item.storeNameSnapshot }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item
                      style="min-width: 165px"
                      label="账单日期"
                    >
                      {{ item.startDate }}~{{ item.endDate }}
                    </a-form-model-item>
                    <a-form-model-item style="min-width: 90px" label="租金单价">
                      {{ item.fee == null ? item.rate + '%' : item.fee }}
                      <span
                        v-if="
                          item.type == 0 || item.type == 2 || item.type == 3
                        "
                        >{{
                          item.feeType == 0
                            ? '元/m².天'
                            : item.feeType == 5
                            ? '元/m².月'
                            : item.feeType == 6
                            ? '元/日'
                            : item.feeType == 1
                            ? '元/月'
                            : item.feeType == 3
                            ? '元/年'
                            : item.feeType == 4
                            ? '元/m²'
                            : item.feeType == 2
                            ? '元'
                            : ''
                        }}</span
                      >
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="合同管理费条款" :span="2">
                  <!-- 管理费 -->
                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    class="flow-d-row"
                  >
                    <a-form-model-item label="物业管理费">
                      {{
                        linkclause.clauseProperty.propertyFee == null
                          ? 0
                          : linkclause.clauseProperty.propertyFee
                      }}
                    </a-form-model-item>
                    <a-form-model-item label="综合管理费">
                      {{
                        linkclause.clauseProperty.commonFe == null
                          ? 0
                          : linkclause.clauseProperty.commonFe
                      }}
                    </a-form-model-item>
                    <a-form-model-item label="装修管理费">
                      {{
                        linkclause.clauseProperty.decorateFee == null
                          ? 0
                          : linkclause.clauseProperty.decorateFee
                      }}
                    </a-form-model-item>
                    <a-form-model-item label="运营推广费">
                      {{
                        linkclause.clauseProperty.adFee == null
                          ? 0
                          : linkclause.clauseProperty.adFee
                      }}
                    </a-form-model-item>
                    <a-form-model-item label="公共能耗及物业代办费">
                      {{
                        linkclause.clauseProperty.publicFee == null
                          ? 0
                          : linkclause.clauseProperty.publicFee
                      }}
                    </a-form-model-item>
                    <a-form-model-item label="建筑垃圾清运费">
                      {{
                        linkclause.clauseProperty.rubbishFee == null
                          ? 0
                          : linkclause.clauseProperty.rubbishFee
                      }}
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <!-- 保证金 -->
                <a-descriptions-item
                  label="合同保证金条款"
                  :span="2"
                  v-if="linkclause.clauseSureties.length > 0"
                >
                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    v-for="item in linkclause.clauseSureties"
                    :key="item.id"
                    class="flow-d-row"
                  >
                    <a-form-model-item label="保证金类型">
                      {{
                        item.type == 0
                          ? '履约保证金'
                          : item.type == 1
                          ? '质量保证金'
                          : item.type == 2
                          ? '装修保证金'
                          : item.type == 3
                          ? '水电保证金'
                          : item.type == 4
                          ? '物业管理服务费保证金'
                          : item.type == 5
                          ? '综合管理服务费保证金'
                          : item.type == 6
                          ? '装修及改造复原保证金'
                          : item.name
                      }}
                    </a-form-model-item>
                    <a-form-model-item label="保证金金额">
                      {{ item.fee }}
                    </a-form-model-item>
                    <a-form-model-item label="备注">
                      {{ item.payDate == null ? '' : item.payDate }}
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <!-- 优惠 -->
                <a-descriptions-item
                  :span="2"
                  v-if="linkclause.clauseDiscounts.length > 0"
                  label="合同优惠条款"
                >
                  <div
                    style="flex-wrap: wrap; justify-content: space-between"
                    v-for="item in linkclause.clauseDiscounts"
                    :key="item.id"
                    class="flow-d-row"
                  >
                    <a-form-model-item label="优惠类型">
                      {{
                        item.type == 0
                          ? '免租期'
                          : item.type == 1
                          ? '装修期'
                          : item.type == 2
                          ? '单价折扣'
                          : item.type == 3
                          ? '单价减免'
                          : '每期应收减免'
                      }}
                    </a-form-model-item>
                    <a-form-model-item
                      v-if="item.type == 0 || item.type == 1"
                      label="优惠"
                    >
                      {{ item.discount
                      }}{{ item.discountType == 0 ? '天' : '月' }}
                    </a-form-model-item>
                    <a-form-model-item v-else label="优惠">
                      {{ item.discount
                      }}{{
                        item.discountType == 0
                          ? '元/m².天'
                          : item.discountType == 5
                          ? '元/m².月'
                          : item.discountType == 6
                          ? '元/日'
                          : item.discountType == 1
                          ? '元/月'
                          : item.discountType == 3
                          ? '元/年'
                          : item.discountType == 4
                          ? '元/m²'
                          : '元'
                      }}
                    </a-form-model-item>
                    <a-form-model-item label="开始期数">
                      {{ item.discountTerm }}
                    </a-form-model-item>
                    <a-form-model-item label="持续期数">
                      {{ item.discountDuration }}
                    </a-form-model-item>
                    <a-form-model-item label="备注">
                      {{ item.note }}
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="正式合同">
                  <a-upload
                    action=""
                    :file-list="linklist.agreement"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
                <a-descriptions-item label="正式合同附件">
                  <a-upload
                    action=""
                    :file-list="linklist.files"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>

                <a-descriptions-item label="铺位" :span="2">
                  <div
                    class="flow-d-row"
                    v-for="item in linklist.stores"
                    :key="item.id"
                  >
                    <a-form-model-item class="ant--input" label="楼栋">
                      {{ item.buildingNameSnapshot }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="楼层">
                      {{ item.floorNameSnapshot }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="铺位名称">
                      {{ item.storeNameSnapshot }}
                    </a-form-model-item>
                    <a-form-model-item class="ant--input" label="计租面积">
                      {{ item.rentAreaSnapshot }}
                    </a-form-model-item>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="提交备注" :span="2">
                  {{ submitNote }}
                </a-descriptions-item>
                <a-descriptions-item label="提交附件">
                  <a-upload
                    action=""
                    :file-list="subfile"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
              </a-descriptions>
              <!-- //审核记录 -->
              <a-descriptions
                layout="vertical"
                bordered
                v-for="item in historylist"
                :key="item.id"
                :column="6"
              >
                <a-descriptions-item label="审核类型">
                  {{ item.linkType }}
                </a-descriptions-item>
                <a-descriptions-item label="审核人">
                  {{ item.auditName }}
                </a-descriptions-item>
                <a-descriptions-item label="审核时间">
                  {{ item.auditTime | time }}
                </a-descriptions-item>
                <a-descriptions-item label="审核备注">
                  {{ item.auditNote }}
                </a-descriptions-item>
                <a-descriptions-item label="审核附件" :span="2">
                  <a-upload
                    action=""
                    :file-list="item.auditAttach"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
              </a-descriptions>
              <a-form-model-item
                class="ant--input"
                style="margin-bottom: 40px"
                label="备注"
              >
                <a-input placeholder="请输入备注" v-model="form.auditNote" />
              </a-form-model-item>
              <a-upload
                action=""
                :multiple="true"
                :file-list="auditAttach"
                :customRequest="handleauditinfo"
                @change="handleauditinfos"
              >
                <a-button> <a-icon type="upload" /> 点击上传 </a-button>
              </a-upload>
              <template slot="footer">
                <a-button @click="onsubmit(2)" type="primary">通过</a-button>
                <a-button @click="onsubmit(3)" type="danger"> 拒绝 </a-button>
                <a-button @click="onauditClose" type="dashed">取消</a-button>
              </template>
            </a-modal>

            <a-modal
              @cancel="onauditClosetwo"
              width="1600px"
              title="审核信息"
              :visible="auditvisibletwo"
            >
              <div class="flow-d-row">
                <a-descriptions
                  class="form-item"
                  :column="2"
                  :title="
                    this.detaillist.linkType == 0
                      ? '基础信息 (铺位调整)'
                      : this.detaillist.linkType == 1
                      ? '基础信息 (意向合同提交)'
                      : this.detaillist.linkType == 3
                      ? '基础信息 (核销)'
                      : this.detaillist.linkType == 21
                      ? '基础信息 (正式合同提交)'
                      : this.detaillist.linkType == 22
                      ? '基础信息 (正式合同重写)'
                      : this.detaillist.linkType == 23
                      ? '基础信息 (正式合同作废)'
                      : this.detaillist.linkType == 24
                      ? '基础信息 (正式合同变更主体)'
                      : '基础信息 (正式合同变更条款)'
                  "
                  bordered
                >
                  <!-- zuo一 -->
                  <a-descriptions-item label="简介">
                    {{ detaillist.description }}
                  </a-descriptions-item>
                  <a-descriptions-item label="意向名称">
                    {{ linklist.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="合同主体" :span="2">
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      class="flow-d-row"
                    >
                      <a-form-model-item style="width: 32%" label="出租方名称">
                        <a-tooltip>
                          <template slot="title">
                            {{ linklist.partyAName }}
                          </template>
                          {{ linklist.partyAName | text }}
                        </a-tooltip>
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="出租方联系电话"
                      >
                        {{ linklist.partyAContact }}
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="出租方联系地址"
                      >
                        <a-tooltip>
                          <template slot="title">
                            {{ linklist.partyAAddress }}
                          </template>
                          {{ linklist.partyAAddress | text }}
                        </a-tooltip>
                      </a-form-model-item>
                      <a-form-model-item style="width: 32%" label="承租方名称">
                        <a-tooltip>
                          <template slot="title">
                            {{ linklist.partyBName }}
                          </template>
                          {{ linklist.partyBName | text }}
                        </a-tooltip>
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="承租方联系电话"
                      >
                        {{ linklist.partyBContact }}
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="承租方联系地址"
                      >
                        <a-tooltip>
                          <template slot="title">
                            {{ linklist.partyBAddress }}
                          </template>
                          {{ linklist.partyBAddress | text }}
                        </a-tooltip>
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item
                    v-if="this.detaillist.linkType == 25"
                    label="合同管理费条款"
                    :span="2"
                  >
                    <!-- 管理费 -->
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      class="flow-d-row"
                    >
                      <a-form-model-item label="物业管理费">
                        {{ linkclause.clauseProperty.propertyFee }}
                      </a-form-model-item>
                      <a-form-model-item label="综合管理费">
                        {{ linkclause.clauseProperty.commonFee }}
                      </a-form-model-item>
                      <a-form-model-item label="装修管理费">
                        {{ linkclause.clauseProperty.decorateFee }}
                      </a-form-model-item>
                      <a-form-model-item label="运营推广费">
                        {{ linkclause.clauseProperty.adFee }}
                      </a-form-model-item>
                      <a-form-model-item label="公共能耗及物业代办费">
                        {{ linkclause.clauseProperty.publicFee }}
                      </a-form-model-item>
                      <a-form-model-item label="建筑垃圾清运费">
                        {{ linkclause.clauseProperty.rubbishFee }}
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <!-- 保证金 -->
                  <a-descriptions-item
                    label="合同保证金条款"
                    :span="2"
                    v-if="linkclause.clauseSureties.length > 0"
                  >
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      v-for="item in linkclause.clauseSureties"
                      :key="item.id"
                      class="flow-d-row"
                    >
                      <a-form-model-item label="保证金类型">
                        {{
                          item.type == 0
                            ? '履约保证金'
                            : item.type == 1
                            ? '质量保证金'
                            : item.type == 2
                            ? '装修保证金'
                            : item.type == 3
                            ? '水电保证金'
                            : item.type == 4
                            ? '物业管理服务费保证金'
                            : item.type == 5
                            ? '综合管理服务费保证金'
                            : item.type == 6
                            ? '装修及改造复原保证金'
                            : item.name
                        }}
                      </a-form-model-item>
                      <a-form-model-item label="保证金金额">
                        {{ item.fee }}
                      </a-form-model-item>
                      <a-form-model-item label="备注">
                        {{ item.payDate == null ? '' : item.payDate }}
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item
                    :span="2"
                    v-if="linkclause.clauseDiscounts.length > 0"
                    label="合同优惠条款"
                  >
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      v-for="item in linkclause.clauseDiscounts"
                      :key="item.id"
                      class="flow-d-row"
                    >
                      <a-form-model-item label="优惠类型">
                        {{
                          item.type == 0
                            ? '免租期'
                            : item.type == 1
                            ? '装修期'
                            : item.type == 2
                            ? '单价折扣'
                            : item.type == 3
                            ? '单价减免'
                            : '每期应收减免'
                        }}
                      </a-form-model-item>
                      <a-form-model-item
                        v-if="item.type == 0 || item.type == 1"
                        label="优惠"
                      >
                        {{ item.discount
                        }}{{ item.discountType == 0 ? '天' : '月' }}
                      </a-form-model-item>
                      <a-form-model-item v-else label="优惠">
                        {{ item.discount
                        }}{{
                          item.discountType == 0
                            ? '元/m².天'
                            : item.discountType == 5
                            ? '元/m².月'
                            : item.discountType == 6
                            ? '元/日'
                            : item.discountType == 1
                            ? '元/月'
                            : item.discountType == 3
                            ? '元/年'
                            : item.discountType == 4
                            ? '元/m²'
                            : '元'
                        }}
                      </a-form-model-item>
                      <a-form-model-item label="开始期数">
                        {{ item.discountTerm }}
                      </a-form-model-item>
                      <a-form-model-item label="持续期数">
                        {{ item.discountDuration }}
                      </a-form-model-item>
                      <a-form-model-item label="备注">
                        {{ item.note }}
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="正式合同">
                    <a-upload
                      action=""
                      :file-list="linklist.agreement"
                      @preview="handlePreview"
                    >
                    </a-upload>
                    <a-modal
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="previewImage"
                      />
                    </a-modal>
                  </a-descriptions-item>
                  <a-descriptions-item label="正式合同附件">
                    <a-upload
                      action=""
                      :file-list="linklist.files"
                      @preview="handlePreview"
                    >
                    </a-upload>
                    <a-modal
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="previewImage"
                      />
                    </a-modal>
                  </a-descriptions-item>
                  <a-descriptions-item label="提交备注" :span="4">
                    {{ submitNote }}
                  </a-descriptions-item>
                  <a-descriptions-item label="提交附件">
                    <a-upload
                      action=""
                      :file-list="subfile"
                      @preview="handlePreview"
                    >
                    </a-upload>
                    <a-modal
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="previewImage"
                      />
                    </a-modal>
                  </a-descriptions-item>
                </a-descriptions>
                <!-- you一 -->
                <a-descriptions
                  class="form-item"
                  :column="2"
                  :title="
                    this.detaillist.linkType == 0
                      ? '基础信息 (铺位调整)'
                      : this.detaillist.linkType == 1
                      ? '基础信息 (意向合同提交)'
                      : this.detaillist.linkType == 3
                      ? '基础信息 (核销)'
                      : this.detaillist.linkType == 21
                      ? '基础信息 (正式合同提交)'
                      : this.detaillist.linkType == 22
                      ? '基础信息 (正式合同重写)'
                      : this.detaillist.linkType == 23
                      ? '基础信息 (正式合同作废)'
                      : this.detaillist.linkType == 24
                      ? '基础信息 (正式合同变更主体)'
                      : '基础信息 (正式合同变更条款)'
                  "
                  bordered
                >
                  <a-descriptions-item label="简介">
                    {{ detaillist.description }}
                  </a-descriptions-item>
                  <a-descriptions-item label="意向名称">
                    {{ linklistnew.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="合同主体" :span="2">
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      class="flow-d-row"
                    >
                      <a-form-model-item style="width: 32%" label="出租方名称">
                        <a-tooltip>
                          <template slot="title">
                            {{ linklist.partyAName }}
                          </template>
                          {{ linklist.partyAName | text }}
                        </a-tooltip>
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="出租方联系电话"
                      >
                        {{ linklistnew.partyAContact }}
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="出租方联系地址"
                      >
                        <a-tooltip>
                          <template slot="title">
                            {{ linklist.partyAAddress }}
                          </template>
                          {{ linklist.partyAAddress | text }}
                        </a-tooltip>
                      </a-form-model-item>
                      <a-form-model-item style="width: 32%" label="承租方名称">
                        <a-tooltip>
                          <template slot="title">
                            {{ linklistnew.partyBName }}
                          </template>
                          {{ linklistnew.partyBName | text }}
                        </a-tooltip>
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="承租方联系电话"
                      >
                        {{ linklistnew.partyBContact }}
                      </a-form-model-item>
                      <a-form-model-item
                        style="width: 30%"
                        label="承租方联系地址"
                      >
                        <a-tooltip>
                          <template slot="title">
                            {{ linklist.partyBAddress }}
                          </template>
                          {{ linklist.partyBAddress | text }}
                        </a-tooltip>
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item
                    v-if="this.detaillist.linkType == 25"
                    label="合同管理费条款"
                    :span="2"
                  >
                    <!-- 管理费 -->
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      class="flow-d-row"
                    >
                      <a-form-model-item label="物业管理费">
                        {{ linkclausenew.clauseProperty.propertyFee }}
                      </a-form-model-item>
                      <a-form-model-item label="综合管理费">
                        {{ linkclausenew.clauseProperty.commonFee }}
                      </a-form-model-item>
                      <a-form-model-item label="装修管理费">
                        {{ linkclausenew.clauseProperty.decorateFee }}
                      </a-form-model-item>
                      <a-form-model-item label="运营推广费">
                        {{ linkclausenew.clauseProperty.adFee }}
                      </a-form-model-item>
                      <a-form-model-item label="公共能耗及物业代办费">
                        {{ linkclausenew.clauseProperty.publicFee }}
                      </a-form-model-item>
                      <a-form-model-item label="建筑垃圾清运费">
                        {{ linkclausenew.clauseProperty.rubbishFee }}
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <!-- 保证金 -->
                  <a-descriptions-item
                    label="合同保证金条款"
                    :span="2"
                    v-if="linkclausenew.clauseSureties.length > 0"
                  >
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      v-for="item in linkclausenew.clauseSureties"
                      :key="item.id"
                      class="flow-d-row"
                    >
                      <a-form-model-item label="保证金类型">
                        {{
                          item.type == 0
                            ? '履约保证金'
                            : item.type == 1
                            ? '质量保证金'
                            : item.type == 2
                            ? '装修保证金'
                            : item.type == 3
                            ? '水电保证金'
                            : item.type == 4
                            ? '物业管理服务费保证金'
                            : item.type == 5
                            ? '综合管理服务费保证金'
                            : item.type == 6
                            ? '装修及改造复原保证金'
                            : item.name
                        }}
                      </a-form-model-item>
                      <a-form-model-item label="保证金金额">
                        {{ item.fee }}
                      </a-form-model-item>
                      <a-form-model-item label="备注">
                        {{ item.payDate == null ? '' : item.payDate }}
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item
                    :span="2"
                    v-if="linkclausenew.clauseDiscounts.length > 0"
                    label="合同优惠条款"
                  >
                    <div
                      style="flex-wrap: wrap; justify-content: space-between"
                      v-for="item in linkclausenew.clauseDiscounts"
                      :key="item.id"
                      class="flow-d-row"
                    >
                      <a-form-model-item label="优惠类型">
                        {{
                          item.type == 0
                            ? '免租期'
                            : item.type == 1
                            ? '装修期'
                            : item.type == 2
                            ? '单价折扣'
                            : item.type == 3
                            ? '单价减免'
                            : '每期应收减免'
                        }}
                      </a-form-model-item>
                      <a-form-model-item
                        v-if="item.type == 0 || item.type == 1"
                        label="优惠"
                      >
                        {{ item.discount
                        }}{{ item.discountType == 0 ? '天' : '月' }}
                      </a-form-model-item>
                      <a-form-model-item v-else label="优惠">
                        {{ item.discount
                        }}{{
                          item.discountType == 0
                            ? '元/m².天'
                            : item.discountType == 5
                            ? '元/m².月'
                            : item.discountType == 6
                            ? '元/日'
                            : item.discountType == 1
                            ? '元/月'
                            : item.discountType == 3
                            ? '元/年'
                            : item.discountType == 4
                            ? '元/m²'
                            : '元'
                        }}
                      </a-form-model-item>
                      <a-form-model-item label="开始期数">
                        {{ item.discountTerm }}
                      </a-form-model-item>
                      <a-form-model-item label="持续期数">
                        {{ item.discountDuration }}
                      </a-form-model-item>
                      <a-form-model-item label="备注">
                        {{ item.note }}
                      </a-form-model-item>
                    </div>
                  </a-descriptions-item>
                  <a-descriptions-item label="正式合同">
                    <a-upload
                      action=""
                      :file-list="linklistnew.agreement"
                      @preview="handlePreview"
                    >
                    </a-upload>
                    <a-modal
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="previewImage"
                      />
                    </a-modal>
                  </a-descriptions-item>
                  <a-descriptions-item label="正式合同附件">
                    <a-upload
                      action=""
                      :file-list="linklistnew.files"
                      @preview="handlePreview"
                    >
                    </a-upload>
                    <a-modal
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="previewImage"
                      />
                    </a-modal>
                  </a-descriptions-item>
                  <a-descriptions-item label="提交备注" :span="4">
                    {{ submitNote }}
                  </a-descriptions-item>
                  <a-descriptions-item label="提交附件">
                    <a-upload
                      action=""
                      :file-list="subfile"
                      @preview="handlePreview"
                    >
                    </a-upload>
                    <a-modal
                      :visible="previewVisible"
                      :footer="null"
                      @cancel="handleCancel"
                    >
                      <img
                        alt="example"
                        style="width: 100%"
                        :src="previewImage"
                      />
                    </a-modal>
                  </a-descriptions-item>
                </a-descriptions>
              </div>
              <a-form-model-item
                class="ant--input"
                style="margin-bottom: 40px"
                label="备注"
              >
                <a-input placeholder="请输入备注" v-model="form.auditNote" />
              </a-form-model-item>
              <a-upload
                action=""
                :multiple="true"
                :file-list="auditAttach"
                :customRequest="handleauditinfo"
                @change="handleauditinfos"
              >
                <a-button> <a-icon type="upload" /> 点击上传 </a-button>
              </a-upload>
              <template slot="footer">
                <a-button @click="onsubmit(2)" type="primary">通过</a-button>
                <a-button @click="onsubmit(3)" type="danger"> 拒绝 </a-button>
                <a-button @click="onauditClosetwo" type="dashed">取消</a-button>
              </template>
            </a-modal>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '审批类型',
    dataIndex: 'linkType',
    scopedSlots: { customRender: 'linkType' },
  },
  {
    title: '提交人',
    dataIndex: 'submitName',
  },
  {
    title: '提交时间',
    dataIndex: 'submitTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '简介',
    dataIndex: 'description',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const column = [
  {
    title: '审批类型',
    dataIndex: 'linkType',
    scopedSlots: { customRender: 'linkType' },
  },
  {
    title: '简介',
    dataIndex: 'description',
  },
  {
    title: '提交人',
    dataIndex: 'submitName',
  },
  {
    title: '提交时间',
    dataIndex: 'submitTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '提交备注',
    dataIndex: 'submitNote',
  },
  {
    title: '审核人',
    dataIndex: 'auditName',
  },
  {
    title: '审核时间',
    dataIndex: 'auditTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '审核结果',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  },
  {
    title: '审核备注',
    dataIndex: 'auditNote',
  },
]
const statusMap = {
  0: {
    status: 'warning',
    text: '提交',
  },
  1: {
    status: 'error',
    text: '撤回',
  },
  2: {
    status: 'success',
    text: '通过',
  },
  3: {
    status: 'error',
    text: '拒绝',
  },
}
const base64 = require('js-base64').Base64
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
import http from '../../../http'
export default {
  data() {
    return {
      column,
      columns,
      audittype: '1',
      visible: false,
      auditvisible: false,
      auditvisibletwo: false,
      previewVisible: false,
      historylist: [],
      previewImage: '',
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      subfile: [],
      params: {
        current: 1,
        pageSize: 10,
        link_type: {
          value: undefined,
          op: '=',
        },
        submit_name: {
          value: '',
          op: '%like%',
        },
        submit_time: {
          value: null,
          op: '>=',
        },
        'submit_time#2': {
          value: null,
          op: '<=',
        },
      },
      total: 0,
      submitNote: '',
      textlist: ['审批类型', '提交人', '提交时间'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      auditcenterlist: [],
      auditHistorylist: [],
      auditsubmitlist: [],
      detaillist: [],
      linktype: '',
      linkclause: {
        clauseProperty: {},
        clauseSureties: [],
        clauseDiscounts: [],
      },
      linklist: {
        bill: {
          billDate: '',
          payDate: '',
          payed: '',
          totalFee: '',
        },
        news: {},
        files: [],
        agreement: [],
      },
      linklistnew: {
        news: {},
        files: [],
        agreement: [],
      },
      linkclausenew: {
        clauseProperty: {},
        clauseSureties: [],
        clauseDiscounts: [],
      },
      Id: '',
      linkClear: {
        allfee: '',
        feeProperty: '',
        surety: '',
        discount: '',
        payedRent: '',
        clear: '',
      },
      selectedRowKey: [],
      auditAttach: [],

      form: {
        id: '',
        auditNote: '',
        auditAttach: '',
        operate: '',
      },
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    },
    text(value) {
      if (!value) return ''
      if (value.length > 12) {
        return value.slice(0, 12) + '...'
      }
      return value
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    handleSearch(e) {
      this.params.current = 1
      e.preventDefault()
      this.fom.validateFields((error, values) => {
        this.params['submit_name'].value = values.提交人
        // console.log(values);
      })
      if (this.audittype == 0) {
        this.getauditCentersub()
      } else if (this.audittype == 1) {
        this.getauditCenter()
      } else if (this.audittype == 2) {
        this.getauditCenteraud()
      }
    },
    handleReset() {
      this.fom.resetFields()
      this.params['submit_time'].value = undefined
      this.params['submit_time#2'].value = undefined
      this.params['link_type'].value = undefined
    },
    callback(e) {
      this.params.current = 1
      this.auditcenterlist = []
      this.auditHistorylist = []
      if (e == 0) {
        this.getauditCentersub()
      } else if (e == 1) {
        this.getauditCenter()
      } else if (e == 2) {
        this.getauditCenteraud()
      }
      this.audittype = e
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      let urls = file.url.replaceAll(
        'sccncloud.oss-cn-shanghai.aliyuncs.com',
        'yunfiles.shangchan.cn'
      )
      if (file.url) {
        var url = urls //要预览文件的访问地址
        const u = 'https://preview.shangchan.cn/preview'
        window.open(
          u + '/onlinePreview?url=' + encodeURIComponent(base64.encode(url))
        )
      }
    },
    handleOkaudit(e) {
      this.getauditaudits(e)
    },
    showaudit() {
      this.visible = true
    },
    //提交
    onsubmit(e) {
      this.form.operate = e
      //   console.log(this.form);
      this.getauditaudit(this.form)
    },
    handleauditinfos({ fileList }) {
      if (this.auditAttach.length > fileList.length) {
        this.auditAttach = fileList
        this.form.auditAttach = JSON.stringify(fileList)
      }
    },
    handleauditinfo(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      this.putObject(
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    async putObject(client, id, name, data, realName) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.auditAttach.push({
            bucket: 'sccncloud2',
            name: item.name,
            realName: realName,
            type: name,
            uid: id,
            status: 'done',
            url: url,
          })
        })
        this.form.auditAttach = JSON.stringify(this.auditAttach)
      } catch (e) {
        console.log(e)
      }
    },
    onauditClose() {
      this.auditvisible = false
      this.form = {
        id: '',
        auditNote: '',
        auditAttach: '',
        operate: '',
      }
      this.previewImage = ''
      this.subfile = []
      this.auditAttach = []
      this.linklist = { bill: {}, news: {} }
    },
    onauditClosetwo() {
      this.auditvisibletwo = false
      this.form = {
        id: '',
        auditNote: '',
        auditAttach: '',
        operate: '',
      }
      this.previewImage = ''
      this.subfile = []
      this.auditAttach = []
      this.linklist = {
        bill: {},
        news: {},
        files: [],
        agreement: [],
      }
      this.linkclause = {
        clauseProperty: {},
        clauseSureties: [],
        clauseDiscounts: [],
      }
      this.linklistnew = {
        news: {},
        files: [],
        agreement: [],
      }
      this.linkclausenew = {
        clauseProperty: {},
        clauseSureties: [],
        clauseDiscounts: [],
      }
    },

    async getauditCenterrecord(id) {
      try {
        const res = await http.getauditCenterrecord(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (data.history.length < 0) {
            return
          }
          let url
          let a = []
          data.history.forEach(item => {
            if (item.auditAttach == null) {
              return
            } else {
              JSON.parse(item.auditAttach).forEach(item => {
                url = client.signatureUrl(item.name)
                a.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              item.auditAttach = a
            }
          })
          this.historylist = data.history
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 编辑
    onEdit(item) {
      //   console.log(item);
      this.submitNote = item.submitNote
      this.form.id = item.id
      if (item.linkType == 24 || item.linkType == 25) {
        this.auditvisibletwo = true
      } else {
        this.auditvisible = true
      }
      this.getauditCenterrecord(item.id)
      this.detaillist = item
      this.getauditCenterlink(item.id)
      let url
      let file = JSON.parse(item.submitAttach)
      let a = []
      if (file.length > 0) {
        file.forEach(item => {
          url = client.signatureUrl(item.name)
          a.push({
            bucket: 'sccncloud2',
            name: item.name,
            type: item.type,
            uid: nanoid(),
            status: 'done',
            url: url,
          })
        })
        this.subfile = a
      }
    },
    //详情
    ondetail(item) {
      this.getauditCenterlink(item.id)
    },
    async getauditaudits(operates) {
      try {
        const res = await http.getauditaudits({
          operate: operates,
          note: this.form.auditNote,
          auditIds: this.selectedRowKey,
        })
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.visible = false
          this.form.auditNote = ''
          this.form.operate = ''
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getauditaudit(form) {
      try {
        const res = await http.getauditaudit(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onauditClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      if (this.audittype == 0) {
        this.getauditCentersub()
      } else if (this.audittype == 1) {
        this.getauditCenter()
      } else if (this.audittype == 2) {
        this.getauditCenteraud()
      }
    },
    async getauditCenteraud() {
      try {
        const res = await http.getauditCenteraud(this.params)
        const { success, data } = res.data
        if (success) {
          this.auditHistorylist = data.audits.records
          this.total = data.audits.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getauditCentersub() {
      try {
        const res = await http.getauditCentersub(this.params)
        const { success, data } = res.data
        if (success) {
          this.auditsubmitlist = data.audits.records
          this.total = data.audits.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },

    async getauditCenter() {
      try {
        const res = await http.getauditCenter(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.auditcenterlist = data.audits.records
          this.total = data.audits.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getauditCenterlink(id) {
      try {
        const res = await http.getauditCenterlink(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (this.detaillist.linkType == 0) {
            this.linklist = data.link
            this.linklist.news = JSON.parse(data.link.news)
          } else if (this.detaillist.linkType == 1) {
            this.linklist = data.link
            let url
            if (JSON.parse(data.link.agreement).length > 0) {
              let agreement = JSON.parse(data.link.agreement)
              let a = []
              agreement.forEach(item => {
                url = client.signatureUrl(item.name)
                a.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              this.linklist.agreement = a
            }
            if (JSON.parse(data.link.files).length > 0) {
              let file = JSON.parse(data.link.files)
              let b = []
              file.forEach(item => {
                url = client.signatureUrl(item.name)
                b.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              this.linklist.files = b
            }
          } else if (this.detaillist.linkType == 3) {
            this.linklist = data.link
          } else if (
            this.detaillist.linkType == 21 ||
            this.detaillist.linkType == 22 ||
            this.detaillist.linkType == 23
          ) {
            this.linklist = data.link
            data.link.clause.clauseTerm.termDurations.forEach(item => {
              let stored = []
              item.stores.forEach(it => {
                stored.push(it.storeId)
              })
              item.store = stored
            })
            this.linkclause = data.link.clause
            let url
            if (JSON.parse(data.link.agreement).length > 0) {
              let agreement = JSON.parse(data.link.agreement)
              let a = []
              agreement.forEach(item => {
                url = client.signatureUrl(item.name)
                a.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              this.linklist.agreement = a
            }
            if (JSON.parse(data.link.files).length > 0) {
              let file = JSON.parse(data.link.files)
              let b = []
              file.forEach(item => {
                url = client.signatureUrl(item.name)
                b.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              this.linklist.files = b
            }
          } else if (
            this.detaillist.linkType == 24 ||
            this.detaillist.linkType == 25
          ) {
            if (data.link.changeParam == null) {
              this.linklist = data.linkOld
              this.linkclause = data.linkOld.clause
              this.linklistnew = data.linkNew
              this.linkclausenew = data.linkNew.clause
            } else {
              this.linklist = data.link
              this.linklistnew = JSON.parse(data.link.changeParam)
            }
          } else if (this.detaillist.linkType == 26) {
            this.linklist = data.linkSign
            this.linkClear = data.linkClear
            data.linkSign.clause.clauseTerm.termDurations.forEach(item => {
              let stored = []
              item.stores.forEach(it => {
                stored.push(it.storeId)
              })
              item.store = stored
            })
            this.linkclause = data.linkSign.clause
            this.linkClear.allfee =
              Number(data.linkClear.feeProperty) +
              Number(data.linkClear.surety) +
              Number(data.linkClear.discount) +
              Number(data.linkClear.payedRent) +
              Number(data.linkClear.clear)
            let url
            if (JSON.parse(data.linkSign.agreement).length > 0) {
              let agreement = JSON.parse(data.linkSign.agreement)
              let a = []
              agreement.forEach(item => {
                url = client.signatureUrl(item.name)
                a.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              this.linklist.agreement = a
            }
            if (JSON.parse(data.linkSign.files).length > 0) {
              let file = JSON.parse(data.linkSign.files)
              let b = []
              file.forEach(item => {
                url = client.signatureUrl(item.name)
                b.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              this.linklist.files = b
            }
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    timer() {
      return setTimeout(() => {
        if (this.audittype == 0) {
          this.getauditCentersub()
        } else if (this.audittype == 1) {
          this.getauditCenter()
        } else if (this.audittype == 2) {
          this.getauditCenteraud()
        }
      }, 100)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getsts()
    this.getauditCenter()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.form-item {
  .ant-form-item {
    height: 60px;
    margin-bottom: 0;
  }
}
.ant-form-item {
  //   height: 80px;
  line-height: 2.5;
  margin-bottom: 0;
}
::v-deep .ant-form-item-label {
  line-height: 20px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 600;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ant--input {
  width: 50%;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 39px;
}
</style>
